/* eslint-disable @typescript-eslint/no-unused-vars */
import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react'
import {getTokenInLocalStorage} from '../Utiles/LocalStorage'
// import { useAuth } from '../../app/modules/auth/core/Auth'

let time_zone = new Date()
  .toString()
  .substring(new Date().toString().indexOf('+') + 0)
  ?.slice(0, 5)
time_zone = time_zone.substring(0, 3) + ':' + time_zone.substring(3)

// generate header part in api
const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_API_URL,
  prepareHeaders: (headers) => {
    var token: any = getTokenInLocalStorage()
    if (token) {
      headers.set('token', token)
      return headers
    }
  },
})

const baseQueryMiddleware = async (args: any, api: any, extraOptions: any) => {
  let result: any = await baseQuery(args, api, extraOptions)
  // if (result.error && result.error.status === 401) {
  //   localStorage.clear()
  //   window.location.href = '/auth'
  // }
  return result
}

export const allApiSlice = createApi({
  reducerPath: 'allApi',
  baseQuery: baseQueryMiddleware,
  endpoints: (builder) => ({
    // get api
    register: builder.query({
      query: (payload) => ({
        url: 'user/sign-up',
        method: 'POST',
        body: payload,
      }),
    }),
    login: builder.query({
      query: (payload) => ({
        url: 'user/sign-in',
        method: 'POST',
        body: payload,
      }),
    }),
    dashboard: builder.query({
      query: (payload) => ({
        url: 'dashboard/',
        method: 'Get',
        params: payload,
      }),
    }),
    feeds: builder.query({
      query: (payload) => ({
        url: 'dashboard/feeds/all',
        method: 'Get',
        params: payload,
      }),
    }),
    assignmentFilter: builder.query({
      query: (payload) => ({
        url: '/assignment/filters',
        method: 'Get',
        params: payload,
      }),
    }),
    event: builder.query({
      query: (payload) => ({
        url: '/events/today',
        method: 'Get',
        params: payload,
      }),
    }),
    ProfileDetails: builder.query({
      query: (payload) => ({
        url: '/user/profile/details',
        method: 'Get',
        params: payload,
      }),
    }),
    events: builder.query({
      query: (payload) => ({
        url: '/events',
        method: 'Get',
        params: payload,
      }),
    }),
    eventDetails: builder.query({
      query: (payload) => ({
        url: `/events/${payload}`,
        method: 'Get',
      }),
    }),
    eventsFilter: builder.query({
      query: (payload) => ({
        url: '/events/filters',
        method: 'Get',
        params: payload,
      }),
    }),
    eventsLike: builder.query({
      query: (payload) => ({
        url: `/events/toggle-like/${payload}`,
        method: 'Get',
      }),
    }),
    subjectList: builder.query({
      query: (payload) => ({
        url: `academics/subject-list/${payload}`,
        method: 'Get',
      }),
    }),
    subjectDetails: builder.query({
      query: (payload) => ({
        url: `academics/subject/${payload}`,
        method: 'Get',
      }),
    }),
    attendance: builder.query({
      query: (payload) => ({
        url: `attendance`,
        method: 'Get',
        params: payload,
      }),
    }),
    attendanceFilter: builder.query({
      query: (payload) => ({
        url: `attendance/filters`,
        method: 'Get',
        params: payload,
      }),
    }),
    attendanceTimeTable: builder.query({
      query: (payload) => ({
        url: `attendance/time-table`,
        method: 'Get',
        params: payload,
      }),
    }),
    attendanceSubject: builder.query({
      query: (payload) => ({
        url: `attendance/subjects`,
        method: 'Get',
        params: payload,
      }),
    }),
    attendanceStatusTable: builder.query({
      query: (payload) => ({
        url: `attendance/status-table`,
        method: 'Get',
        params: payload,
      }),
    }),
    attendanceHoliday: builder.query({
      query: (payload) => ({
        url: `attendance/holidays`,
        method: 'Get',
        params: payload,
      }),
    }),
    courseFilter: builder.query({
      query: (payload) => ({
        url: `courses/filters`,
        method: 'Get',
        params: payload,
      }),
    }),
    courseDetails: builder.query({
      query: (payload) => ({
        url: `courses/${payload?.course_id}`,
        method: 'Get',
      }),
    }),
    achievements: builder.query({
      query: (payload) => ({
        url: `achievements/${payload}`,
        method: 'Get',
      }),
    }),
    facultyDetails: builder.query({
      query: (payload) => ({
        url: `faculty/details/${payload}`,
        method: 'Get',
      }),
    }),
    jobFilter: builder.query({
      query: (payload) => ({
        url: `/jobs/filters`,
        method: 'GET',
        params: payload,
      }),
    }),
    jobDetails: builder.query({
      query: (payload) => ({
        url: `/jobs/${payload}`,
        method: 'GET',
      }),
    }),
    jobApplied: builder.query({
      query: (payload) => ({
        url: `/jobs/apply/${payload}`,
        method: 'GET',
      }),
    }),
    assessment: builder.query({
      query: (payload) => ({
        url: `assessment`,
        method: 'Get',
        params: payload,
      }),
    }),
    assessmentFilter: builder.query({
      query: (payload) => ({
        url: `assessment/filters`,
        method: 'Get',
        params: payload,
      }),
    }),
    assessmentPerformance: builder.query({
      query: (payload) => ({
        url: `assessment/performance`,
        method: 'Get',
        params: payload,
      }),
    }),
    assessmentDownload: builder.query({
      query: (payload) => ({
        url: `assessment/marksheet/download`,
        method: 'Get',
        params: payload,
      }),
    }),
    assessmentList: builder.query({
      query: (payload) => ({
        url: `assessment/list`,
        method: 'Get',
        params: payload,
      }),
    }),
    assessmentView: builder.query({
      query: (payload) => ({
        url: `assessment/view`,
        method: 'Get',
        params: payload,
      }),
    }),
    assessmentUpcoming: builder.query({
      query: (payload) => ({
        url: `assessment/upcoming`,
        method: 'Get',
        params: payload,
      }),
    }),
    academicFilter: builder.query({
      query: (payload) => ({
        url: `academics/filters`,
        method: 'Get',
        params: payload,
      }),
    }),
    getCertification: builder.query({
      query: (payload) => ({
        url: `/achievements/data/certificate-issuer`,
        method: 'Get',
        params: payload,
      }),
    }),
    getSkills: builder.query({
      query: (payload) => ({
        url: `/achievements/data/skill`,
        method: 'Get',
        params: payload,
      }),
    }),
    getOrganization: builder.query({
      query: (payload) => ({
        url: `/achievements/data/organisation`,
        method: 'Get',
        params: payload,
      }),
    }),
    getBlogList: builder.query({
      query: (payload) => ({
        url: `/blogs/blog-list?blog_type=${payload}`,
        method: 'Get',
      }),
    }),
    getMyBlogs: builder.query({
      query: (payload) => ({
        url: `/blogs/my-blogs/${payload}`,
        method: 'Get',
      }),
    }),
    getMyDrafts: builder.query({
      query: (payload) => ({
        url: `/blogs/my-draft/${payload}`,
        method: 'Get',
      }),
    }),
    blogDetails: builder.query({
      query: (payload) => ({
        url: `/blogs/${payload}`,
        method: 'Get',
      }),
    }),
    getUserDataDropDown: builder.query({
      query: (payload) => ({
        url: `/user/data`,
        method: 'Get',
        params: payload,
      }),
    }),
    // post api
    UpdatePassword: builder.mutation({
      query: (payload) => ({
        url: '/user/password/update',
        method: 'POST',
        body: payload,
      }),
    }),
    course: builder.query({
      query: (payload) => ({
        url: `courses/`,
        method: 'POST',
        body: payload,
      }),
    }),
    assignment: builder.mutation({
      query: (payload) => ({
        url: 'assignment/',
        method: 'POST',
        body: payload,
      }),
    }),
    certification: builder.mutation({
      query: (payload) => ({
        url: `achievements/certification/save`,
        method: 'POST',
        body: payload,
      }),
    }),
    skills: builder.mutation({
      query: (payload) => ({
        url: `achievements/skill/save`,
        method: 'POST',
        body: payload,
      }),
    }),
    project: builder.mutation({
      query: (payload) => ({
        url: `achievements/project/save`,
        method: 'POST',
        body: payload,
      }),
    }),
    experience: builder.mutation({
      query: (payload) => ({
        url: `achievements/experience/save`,
        method: 'POST',
        body: payload,
      }),
    }),
    assessmentSubmit: builder.mutation({
      query: (payload) => ({
        url: `assessment/submit/${payload.assessment_id}`,
        method: 'POST',
        body: payload.body,
      }),
    }),
    jobsDetails: builder.mutation({
      query: (payload) => ({
        url: `/jobs`,
        method: 'POST',
        body: payload,
      }),
    }),
    AddBlog: builder.mutation({
      query: (payload) => ({
        url: `/blogs/save`,
        method: 'POST',
        body: payload,
      }),
    }),
    AddDraft: builder.mutation({
      query: (payload) => ({
        url: `blogs/drafts/save`,
        method: 'POST',
        body: payload,
      }),
    }),
    addUserDetails: builder.mutation({
      query: (payload) => ({
        url: `/user/profile/details/save`,
        method: 'POST',
        body: payload,
      }),
    }),
    // delete api
    deleCertification: builder.mutation({
      query: (payload) => ({
        url: `achievements/certification/${payload}`,
        method: 'DELETE',
      }),
    }),
    deleSkills: builder.mutation({
      query: (payload) => ({
        url: `achievements/skill/${payload}`,
        method: 'DELETE',
      }),
    }),
    deleProject: builder.mutation({
      query: (payload) => ({
        url: `achievements/project/${payload}`,
        method: 'DELETE',
      }),
    }),
    deleExperience: builder.mutation({
      query: (payload) => ({
        url: `achievements/experience/${payload}`,
        method: 'DELETE',
      }),
    }),
    deleDraft: builder.mutation({
      query: (payload) => ({
        url: `blogs/drafts/${payload.draft_id}?author_id=${payload.author_id}`,
        method: 'DELETE',
      }),
    }),
    deleBlog: builder.mutation({
      query: (payload) => ({
        url: `blogs/blogs/${payload.bloag_id}?author_id=${payload.author_id}`,
        method: 'DELETE',
      }),
    }),
  }),
})

export const {
  // method get
  useLazyRegisterQuery,
  useLazyLoginQuery,
  useLazyDashboardQuery,
  useLazyFeedsQuery,
  useLazyAssignmentFilterQuery,
  useLazyEventQuery,
  useLazyProfileDetailsQuery,
  useLazyEventsQuery,
  useLazyEventDetailsQuery,
  useLazyEventsLikeQuery,
  useLazyEventsFilterQuery,
  useLazySubjectListQuery,
  useLazySubjectDetailsQuery,
  useLazyAttendanceQuery,
  useLazyAttendanceFilterQuery,
  useLazyAttendanceTimeTableQuery,
  useLazyAttendanceSubjectQuery,
  useLazyAttendanceStatusTableQuery,
  useLazyAttendanceHolidayQuery,
  useLazyCourseFilterQuery,
  useLazyCourseDetailsQuery,
  useLazyAchievementsQuery,
  useLazyFacultyDetailsQuery,
  useLazyJobFilterQuery,
  useLazyJobDetailsQuery,
  useLazyJobAppliedQuery,
  useLazyAssessmentQuery,
  useLazyAssessmentFilterQuery,
  useLazyAssessmentPerformanceQuery,
  useLazyAssessmentDownloadQuery,
  useLazyAssessmentListQuery,
  useLazyAssessmentViewQuery,
  useLazyAssessmentUpcomingQuery,
  useLazyAcademicFilterQuery,
  useLazyGetCertificationQuery,
  useLazyGetSkillsQuery,
  useLazyGetOrganizationQuery,
  useLazyGetBlogListQuery,
  useLazyBlogDetailsQuery,
  useLazyGetMyBlogsQuery,
  useLazyGetMyDraftsQuery,
  useLazyGetUserDataDropDownQuery,
  // method post
  useUpdatePasswordMutation,
  useLazyCourseQuery,
  useCertificationMutation,
  useSkillsMutation,
  useProjectMutation,
  useExperienceMutation,
  useAssessmentSubmitMutation,
  useJobsDetailsMutation,
  useAssignmentMutation,
  useAddBlogMutation,
  useAddDraftMutation,
  useAddUserDetailsMutation,
  // method delete
  useDeleCertificationMutation,
  useDeleSkillsMutation,
  useDeleProjectMutation,
  useDeleExperienceMutation,
  useDeleDraftMutation,
  useDeleBlogMutation,
} = allApiSlice
